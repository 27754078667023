<template>
  <i class="icon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 15V5C18 4.20435 17.6839 3.44129 17.1213 2.87868C16.5587 2.31607 15.7956 2 15 2H5C4.20435 2 3.44129 2.31607 2.87868 2.87868C2.31607 3.44129 2 4.20435 2 5V15C2 15.7956 2.31607 16.5587 2.87868 17.1213C3.44129 17.6839 4.20435 18 5 18H15C15.7956 18 16.5587 17.6839 17.1213 17.1213C17.6839 16.5587 18 15.7956 18 15ZM4 5C4 4.73478 4.10536 4.48043 4.29289 4.29289C4.48043 4.10536 4.73478 4 5 4H15C15.2652 4 15.5196 4.10536 15.7071 4.29289C15.8946 4.48043 16 4.73478 16 5V9.36L14.92 8.27C14.44 7.78988 13.7889 7.5201 13.11 7.52C12.4315 7.52228 11.7812 7.79173 11.3 8.27L10.39 9.18L9.58 8.37C9.03194 7.83074 8.29388 7.52851 7.525 7.52851C6.75612 7.52851 6.01806 7.83074 5.47 8.37L4 9.85V5ZM4.12 15.45C4.04289 15.3124 4.00162 15.1577 4 15V12.67L6.88 9.79C6.96456 9.70504 7.06507 9.63762 7.17576 9.59162C7.28645 9.54561 7.40513 9.52193 7.525 9.52193C7.64487 9.52193 7.76356 9.54561 7.87424 9.59162C7.98493 9.63762 8.08544 9.70504 8.17 9.79L9 10.6L4.12 15.45ZM12.72 9.69C12.7693 9.63577 12.8296 9.59259 12.8968 9.56329C12.964 9.53399 13.0367 9.51924 13.11 9.52C13.1833 9.51924 13.256 9.53399 13.3232 9.56329C13.3904 9.59259 13.4507 9.63577 13.5 9.69L16 12.18V15C16 15.2652 15.8946 15.5196 15.7071 15.7071C15.5196 15.8946 15.2652 16 15 16H6.4L12.72 9.69ZM21 6C20.7348 6 20.4804 6.10536 20.2929 6.29289C20.1054 6.48043 20 6.73478 20 7V17C20 17.7956 19.6839 18.5587 19.1213 19.1213C18.5587 19.6839 17.7956 20 17 20H7C6.73478 20 6.48043 20.1054 6.29289 20.2929C6.10536 20.4804 6 20.7348 6 21C6 21.2652 6.10536 21.5196 6.29289 21.7071C6.48043 21.8946 6.73478 22 7 22H17C18.3261 22 19.5979 21.4732 20.5355 20.5355C21.4732 19.5979 22 18.3261 22 17V7C22 6.73478 21.8946 6.48043 21.7071 6.29289C21.5196 6.10536 21.2652 6 21 6Z"
        fill="white"
      />
    </svg>
  </i>
</template>

<script>
export default {
  name: "MediaIcon",
};
</script>
