<template>
  <main class="main orgs">
    <div class="container">
      <div v-if="org" class="main__inner">
        <section class="section section__org-title">
          <div class="org__title-wrap">
            <i v-if="org.icon" class="icon" v-html="org.icon"></i>
            <h2>{{ org.title }}</h2>
          </div>
        </section>
        <section v-if="org.minister" class="section section__org-worker">
          <div class="minister__container">
            <div v-if="org.minister.head_img" class="minister__img">
              <img
                :src="org.minister.head_img | image($store.state.api)"
                :alt="org.minister.head_img | image_alt"
              />
            </div>
            <div class="minister__info">
              <div class="minister__name">
                {{ org.minister.surname }} <br />
                {{ org.minister.name }} <br />
                {{ org.minister.patronymic }}
              </div>
              <div v-if="org.minister.post && org.minister.post.title" class="minister__description">
                {{ org.minister.post.title }}
              </div>
              <div v-if="org.department_info" class="minister__contacts">
                <div v-if="org.department_info.phone" class="minister__phone">
                  {{ org.department_info.phone }}
                </div>
                <a
                  v-if="org.department_info.email"
                  :href="'mailto:' + org.department_info.email"
                  class="link__blue"
                >
                  {{ org.department_info.email }}
                </a>
              </div>
            </div>
          </div>
        </section>
        <section class="section section__org-info">
          <div v-if="editorHasText(org.about)" class="org-info__block">
            <h3 class="org-info__title">О ведомстве</h3>
            <div class="org-info__text">
              <EditorJSComponent :text="JSON.parse(org.about)" v-if="org.about" />
            </div>
          </div>
          <div class="org-info__block">
            <h3 class="org-info__title">Контактная информация</h3>
            <div class="org__contacts">
              <div v-if="org.address" class="org__contacts-item">
                <div class="org__contacts-title">Адрес:</div>
                <div class="org__contacts-info">
                  {{ org.address }}
                </div>
              </div>
              <div v-if="org.department_info && org.department_info.phone" class="org__contacts-item">
                <div class="org__contacts-title">Телефоны:</div>
                <div class="org__contacts-info">
                  {{ org.department_info.phone }}
                </div>
              </div>
              <div v-if="org.url" class="org__contacts-item">
                <div class="org__contacts-title">Официальный сайт:</div>
                <a :href="org.url" class="org__contacts-info" target="_blank"> {{ org.url }} </a>
              </div>
              <div v-if="org.department_info && org.department_info.email" class="org__contacts-item">
                <div class="org__contacts-title">Электронная почта:</div>
                <div class="org__contacts-info">
                  {{ org.department_info.email }}
                </div>
              </div>
              <div v-if="editorHasText(org.schedule)" class="org__contacts-item">
                <div class="org__contacts-title">Режим работы:</div>
                <div class="org__contacts-info">
                  <EditorJSComponent :text="JSON.parse(org.schedule)" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import EditorJSComponent from "components/EditorJSComponent.vue";

export default {
  name: "LocalOrganization",
  async asyncData({ store, route }) {
    await store.dispatch("GET_OPEN_ORG", route.params.id);
  },
  components: {
    EditorJSComponent,
  },
  computed: {
    org() {
      return this.$store.state.org_open_page;
    },
  },
  methods: {
    isJson(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    editorHasText(text) {
      if (this.isJson(text)) {
        let parsedText = JSON.parse(text);
        return !!(parsedText && parsedText.blocks && parsedText.blocks.length);
      }
      return !!text;
    },
  },
  metaInfo() {
    return this.$seo(
      "common",
      "Официальный сайт администрации города Махачкалы",
      "",
      "",
      "Официальный сайт администрации города Махачкалы",
      "",
      ""
    );
  },
  jsonld() {
    return {
      "@context": "https://schema.org",
      "@type": "Organization",
    };
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/minister_org.styl"
</style>
